import styled from 'styled-components';

const Card = styled.div`
  background-color: #2F52A0;
  color: white;

  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: justify;

  width: 40rem;
  max-width: 80%;
  padding: 0rem 1rem;

  position: relative;

  & > p {
    margin: 2rem 0rem;
    ul {
      margin-left: 2rem;
    }
    :last-of-type{
      margin-bottom: 0rem;
    }
  }

  &::after {
    background-color: #2DAFE6;

    position: absolute;
    z-index: -1;
    top: 2rem;
    bottom: -2rem;
    right: -2rem;
    left: 2rem;

    content: " ";
  }
`;

export default Card;
