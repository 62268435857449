import styled from 'styled-components';

const Logo = styled.h1`
  background-image: url("/img/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  width: 66%;
  min-width: 100vw;
  height: 50%;
  min-height: 200px;
  margin: auto;
`;

export default Logo;
