import styled, { css } from 'styled-components';

interface ButtonProps {
  rounded?: boolean;
  fixed?: boolean;
  [key: string]: unknown;
}

const common = css<ButtonProps>`
  cursor: pointer;

  font-family: 'Nunito Sans', sans-serif;
  color: white;
  background-color: #2DAFE6;
  
  padding: 0.5em 1.5rem;
  margin: 2.5rem;

  ${({ rounded }) => rounded && css`
    border-radius: 1000px;
    padding: 1em 1rem;
  `}

  ${({ fixed }) => fixed && css`
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    margin: 0px;
  `}
`;

export const LinkButton = styled.a`
  text-decoration: none !important;
  ${common}
`;

export const ClassicButton = styled.button`
  border: none;
  ${common}
`;
