import styled from 'styled-components';

const Wrapper = styled.header`
  background-image: url("/img/header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  width: 100%;
  height: 100vh;

  text-align: center;
`;

export default Wrapper;
