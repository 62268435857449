import styled from 'styled-components';

interface FlexProps {
  reverse?: boolean;
}

const Flex = styled.section<FlexProps>`
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;

  flex-direction: row${({ reverse }) => reverse && '-reverse'};
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export default Flex;
