const serialize = (src: unknown): string => {
  if (!src) {
    return '';
  }

  return (src as { toString: () => string })
    .toString()
    .toString()
    .toLowerCase()
    .replaceAll(' / ', '-')
    .replaceAll(' ', '-');
};

export default serialize;
