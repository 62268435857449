import styled from 'styled-components';

const Nav = styled.nav`
  height: 35%;
  color: white;
  font-weight: 900;
  font-size: 2.5rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: left;
  margin-bottom: 2rem;

  display: flex;
  justify-content: space-around;
  align-items: end;

  @media (max-width: 900px) {
    flex-direction: column;
    line-height: 1.5rem;
    margin: 2rem auto;
  }

  & > h2 {
    font-family: 'Nunito Sans', sans-serif;
  }
`;

export default Nav;
