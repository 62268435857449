import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import scrollTo from '../../utils/scrollTo';
import serialize from '../../utils/serialize';

const NavItem: FC = ({ children }) => {
  const handleClick = useCallback(() => scrollTo(children), [children]);

  return (
    <h2>
      <Link to={`#${serialize(children)}`} onClick={handleClick}>
        {children}
      </Link>
    </h2>
  );
};

export default NavItem;
