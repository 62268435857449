import styled from 'styled-components';

const Title = styled.h2`
  margin: 3rem;
  font-size: 3.5rem;
  font-weight: 900;
  font-family: 'Nunito Sans', sans-serif;
`;

export default Title;
