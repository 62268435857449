import React, { FC, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import MarkdownWrapper from '../components/MarkdownWrapper';

import privacyPolicy from '../markdown/privacy_policy.md';

const PrivacyPolicy: FC = () => {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(privacyPolicy)
      .then((response) => response.text())
      .then((data) => setMarkdown(data));
  }, []);

  return (
    <MarkdownWrapper>
      <ReactMarkdown>
        {markdown}
      </ReactMarkdown>
    </MarkdownWrapper>
  );
};

export default PrivacyPolicy;
