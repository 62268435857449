import React, { FC } from 'react';
import styled from 'styled-components';
import DownArrowIcon from '../down-arrow.svg';

interface ButtonProps {
  onClick: (event: unknown) => void;
}

const Wrapper = styled.button`
  background-color: transparent;
  border: none;
  width: 4rem;
  cursor: pointer;
`;

const Button: FC<ButtonProps> = ({ onClick }) => (
  <Wrapper type="button" onClick={onClick}>
    <img src={DownArrowIcon} alt="v" />
  </Wrapper>
);

export default Button;
