import styled from 'styled-components';

const MarkdownWrapper = styled.div`
  margin: 1rem;
  font-family: 'Open Sans', sans-serif;

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito Sans', sans-serif;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  strong {
    font-size: 1.1rem;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bold;
  }

  ul {
    margin-left: 1rem;
  }
`;

export default MarkdownWrapper;
