import React, { FC } from 'react';

import Wrapper from './Wrapper';
import Logo from './Logo';
import Nav from './Nav';
import Button from './Button';
import NavItem from './NavItem';
import scrollTo from '../../utils/scrollTo';

const Header: FC = () => (
  <Wrapper id="top">
    <Logo />
    <Nav>
      <NavItem>CONSULTING CLOUD / K8S</NavItem>
      <NavItem>AUDIT CYBERSÉCURITÉ</NavItem>
      <NavItem>CONSULTING RGPD / DPO</NavItem>
    </Nav>
    <Button onClick={() => scrollTo('AUDIT CYBERSÉCURITÉ')} />
  </Wrapper>
);

export default Header;
