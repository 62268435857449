import React, { FC } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Landing from './pages/Landing';
import Legal from './pages/Legal';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App: FC = () => (
  <Router>
    <Switch>
      <Route path="/privacy" component={PrivacyPolicy} />
      <Route path="/legal" component={Legal} />
      <Route path="/" component={Landing} />
    </Switch>
  </Router>
);

export default App;
