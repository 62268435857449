import styled from 'styled-components';

const Footer = styled.footer`
  width: 100%;
  
  & > p {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;

    & > a {
      font-size: 1.1rem;
      color: black;
    }

    & > span {
      margin: 0 0.5rem;
    }
  }
`;

export default Footer;
