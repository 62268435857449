import styled from 'styled-components';

const Wrapper = styled.section`
  width: 100%;
  height: 100vh;
  text-align: center;
  padding-top: 0.5rem;
`;

export default Wrapper;
