import React, { FC, ReactChild } from 'react';

import Wrapper from './Wrapper';
import Title from './Title';
import Flex from '../Flex';
import Img from './Img';
import Card from './Card';
import serialize from '../../utils/serialize';

interface PageProps {
  imagePosition?: 'LEFT' | 'RIGHT';
  imageSrc: string;
  title: string;
  children: ReactChild | ReactChild[];
}

const Page: FC<PageProps> = ({
  imagePosition = 'LEFT',
  imageSrc,
  title,
  children,
}) => (
  <Wrapper id={serialize(title)}>
    <Title>
      {title}
    </Title>
    <Flex reverse={imagePosition === 'RIGHT'}>
      <Img src={imageSrc} alt={title} />
      <Card>
        {children}
      </Card>
    </Flex>
  </Wrapper>
);

export default Page;
