import React, { FC } from 'react';
import { useWindowScrollPosition } from 'rooks';
import { ClassicButton } from './Buttons';
import scrollTo from '../utils/scrollTo';
import DownArrowIcon from './down-arrow.svg';

const ScrollToTopButton: FC = () => {
  const { scrollY } = useWindowScrollPosition();

  if (!scrollY) {
    return null;
  }

  return (
    <ClassicButton
      rounded
      fixed
      type="button"
      onClick={() => scrollTo('top')}
    >
      <img src={DownArrowIcon} alt="v" style={{ width: '1rem', transform: 'rotate(180deg)' }} />
    </ClassicButton>
  );
};

export default ScrollToTopButton;
