import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { LinkButton } from '../components/Buttons';
import Flex from '../components/Flex';
import Footer from '../components/Footer';

import Header from '../components/Header';
import Page from '../components/Page';
import ScrollToTopButton from '../components/ScrollToTopButton';

const contactLink = 'https://drive.make-it.fr/apps/calendar/appointment/mBXETJwJeYGq';

const Landing: FC = () => (
  <>
    <Header />
    <Page
      title="AUDIT CYBERSÉCURITÉ"
      imageSrc="/img/hacker.png"
    >
      <p>
        En étroite collaboration avec un Hacker Étique,
        nous avons selectionné un panel complet d’outils
        pour scanner en profondeur votre Système d’Information.
      </p>
      <p>
        En combinant son talent pour traquer des vulnérabilités
        à nos expertises techniques et juridiques, nous
        pouvons vous fournir une expérience complète&nbsp;:
      </p>
      <p>
        <ul>
          <li>
            Un audit Cybersécurité de pointe
          </li>
          <li>
            Une analyse  des conséquences sur d’éventuelles
            certifications, labelisations ou assurances
          </li>
          <li>
            Un plan d’action concret pour  solutionner chaque point faible de votre SI
          </li>
        </ul>
      </p>
      <Flex>
        <LinkButton href={contactLink}>
          NOUS CONTACTER
        </LinkButton>
      </Flex>
    </Page>
    <Page
      title="CONSULTING RGPD / DPO"
      imageSrc="/img/gdpr.png"
      imagePosition="RIGHT"
    >
      <p>
        Pour réduire la fracture entre vos équipes techniques et le reste de l’entreprise,
        notre juriste spécialisée dans le droit du numérique est la personne qu’il vous faut.
      </p>
      <p>
        Forte de 7 ans d’étroite collaboration avec divers métiers techniques, Laura saura
        parler avec, que dis-je, comprendre vos équipes techniques et vous donner la
        sensation d’enfin avancer dans la bonne direction.
      </p>
      <p>
        Qu’il s’agisse d’I.A, de Privacy by design, ou d’autre termes barbares techniques,
        vous serez entre de bonnes mains avec nous.
      </p>
      <Flex>
        <LinkButton href={contactLink}>
          NOUS CONTACTER
        </LinkButton>
      </Flex>
    </Page>
    <Page
      title="CONSULTING CLOUD / K8S"
      imageSrc="/img/cloud.png"
    >
      <p>
        Vous avez besoin de faire le point sur votre SI ? Faire un bilan de l’existant et
        confronter sa performance au meilleur du marché ? Vous êtes au bon endroit.
      </p>
      <p>
        En autoformation continue depuis près de 10 ans, notre architecte cloud performe
        sur les inovations techniques. Avec ses idées “outside the box”, il aura surement
        la réponse parfaite à tout vos problèmes d’infrastructure cloud ou logiciel.
        Et il est certain que seul lui pouvait y penser.
      </p>
      <p>
        Tant que Laura sera là pour traduire, Olivier sera l’homme de la situation.
      </p>
      <Flex>
        <LinkButton href={contactLink}>
          NOUS CONTACTER
        </LinkButton>
      </Flex>
    </Page>
    <Footer>
      <p>
        <Link to="/legal">Mentions légales</Link>
        <span>·</span>
        {' '}
        <Link to="/privacy">Politique de confidentialité</Link>
      </p>
    </Footer>
    <ScrollToTopButton />
  </>
);

export default Landing;
